import Trix from 'trix'

import { icon_tag } from '../../utils'

function buildToolbar() {
  const { lang } = Trix.config

  return `
    <div class="row mb-2 trix-toolbar" >
      <div class='col-auto'>
        <div class="btn-group trix-button-group--text-tools" data-trix-button-group="text-tools">
          <button type="button" class="btn btn-light" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1">${icon_tag("bold")}</button>
          <button type="button" class="btn btn-light" data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1">${icon_tag("italic")}</button>
          <button type="button" class="btn btn-light" data-trix-attribute="strike" title="${lang.strike}" tabindex="-1">${icon_tag("strikethrough")}</button>
          <button type="button" class="btn btn-light" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${lang.link}" tabindex="-1">${icon_tag("link")}</button>
        </div>
      </div>
      <div class='col-auto'>
        <div class="btn-group trix-button-group--block-tools" data-trix-button-group="block-tools">
          <button type="button" class="btn btn-light" data-trix-attribute="heading1" title="${lang.heading1}" tabindex="-1">${icon_tag("heading")}</button>
          <button type="button" class="btn btn-light" data-trix-attribute="quote" title="${lang.quote}" tabindex="-1">${icon_tag("quote-right")}</button>
          <button type="button" class="btn btn-light" data-trix-attribute="code" title="${lang.code}" tabindex="-1">${icon_tag("code")}</button>
          <button type="button" class="btn btn-light" data-trix-attribute="bullet" title="${lang.bullets}" tabindex="-1">${icon_tag("list-ul")}</button>
          <button type="button" class="btn btn-light" data-trix-attribute="number" title="${lang.numbers}" tabindex="-1">${icon_tag("list-ol")}</button>
          <button type="button" class="btn btn-light" data-trix-action="decreaseNestingLevel" title="${lang.outdent}" tabindex="-1">${icon_tag("outdent")}</button>
          <button type="button" class="btn btn-light" data-trix-action="increaseNestingLevel" title="${lang.indent}" tabindex="-1">${icon_tag("indent")}</button>
        </div>
      </div>
      <div class='col-auto'>
        <div class='btn-group trix-button-group--file-tools' data-trix-button-group="file-tools">
          <button type="button" class="btn btn-light" data-trix-action="attachFiles" title="${lang.attachFiles}" tabindex="-1">${icon_tag("paperclip")}</button>
        </div>
      </div>

      <div class='ms-auto col-auto'>
        <div class='btn-group trix-button-group--history-tools' data-trix-button-group="history-tools">
          <button type="button" class="btn btn-light" data-trix-action="undo" data-trix-key="z" title="${lang.undo}" tabindex="-1">${icon_tag("undo")}</button>
          <button type="button" class="btn btn-light" data-trix-action="redo" data-trix-key="shift+z" title="${lang.redo}" tabindex="-1">${icon_tag("redo")}</button>
        </div>
      </div>
    </div>

    <div class="trix-dialogs" data-trix-dialogs>
      <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
        <div class="row gx-3">
          <div class='col'><input type="url" name="href" class="form-control" placeholder="${lang.urlPlaceholder}" aria-label="#{lang.url}" required data-trix-input></div>
          <div class='col-auto'>
            <div class="btn-group">
              <input type="button" class="btn btn-outline-primary" value="${lang.link}" data-trix-method="setAttribute">
              <input type="button" class="btn btn-outline-primary" value="${lang.unlink}" data-trix-method="removeAttribute">
            </div>
          </div>
        </div>
      </div>
    </div>
  `
}

Trix.config.toolbar.getDefaultHTML = buildToolbar