import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["button"]


  get disableable() { return (this.hasButtonTarget ? this.buttonTarget : this.element)}
  get disableWithHTML() { return this.disableable.dataset.disableWith }

  connect() {
    this.originalHTML = this.disableable.innerHTML
  }

  disable() {
    this.disableable.innerHTML = this.disableWithHTML
    this.disableable.disabled = true
  }

  enable() {
    this.disableable.disabled = false
    this.disableable.innerHTML = this.originalHTML
  }

}
