import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkbox"
export default class extends Controller {
  static targets = ["checkbox"]

  get inputTarget() { return (this.hasCheckboxTarget ? this.checkboxTarget : this.element) }

  toggle() {
    console.log("Toggling", this.inputTarget)
    const cb = this.inputTarget
    cb.checked = !cb.checked
  }
}
