import { nodeIsAttachmentElement } from 'trix'

export function getHexColor(color) {
  if (!color){
    return ""
  }
  if (/^#/.test(color)) {
    return color
  }

  const rgbValues = getRGBValues(color)
  const hexValues = rgbValues.map(numberToHex)
  return "#" + hexValues.join("")
}

function numberToHex(number){
  return `0${number.toString(16)}`.slice(-2).toUpperCase()
}

function getRGBValues(color){
  const match = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)/i)

  let r, g, b;

  if (match) {
    let _;

    [ b, g, r, _] = match.reverse()
  } else {
    canvas = document.createElement("canvas")
    canvas.height = canvas.width = 1
    context = canvas.getContext("2d")
    context.fillStyle = color
    context.fillRect(0, 0, 1, 1)

    const {data} = context.getImageData(0, 0, 1, 1)
    [r, g, b] = data
  }

  return [r, g, b].map(Number)
}

export function createColorParser() {
  return function(element) {
    if (nodeIsAttachmentElement(element)) {
      return false
    }

    while (element && element.tagName != "TRIX-EDITOR") {

      const styleColor = element.style[this.styleProperty]
      if (styleColor) {
        const hexColor = getHexColor(styleColor)

        if (hexColor != "#000000") {
          return hexColor
        }
      }

      element = element.parentElement
    }
  }
}
