import Trix from 'trix';
import { createColorParser, getHexColor } from "./colors";

Trix.config.textAttributes.foregroundColor = {
  inheritable: true,
  styleProperty: "color",
  parser: createColorParser()
}

Trix.config.textAttributes.backgroundColor = {
  inheritable: true,
  styleProperty: "backgroundColor",
  parser: createColorParser()
}
