import { argumentsToArray } from '../../../utils'
import { objectsAreEqual } from 'trix'

export default class TrixAttributeObserver {
  get editor() { return this.element.editor }

  constructor(element, callback) {
    this.element = element
    this.callback = callback
    this.observedAttributes = []
  }

  observe() {
    this.observedAttributes = argumentsToArray(arguments).flat()

    this._installEventListeners()
  }

  _installEventListeners() {
    this.element.addEventListener("trix-attributes-change", this._didChangeAttributes.bind(this))
  }

  _didChangeAttributes() {
    const attributes = this._getCommonValuesOfObservedAttributes()

    if (!this._attributesEqual(this.previousAttributes, attributes)) {
      this.previousAttributes = attributes
      this.callback(attributes)
    }
  }

  _getCommonValuesOfObservedAttributes() {
    const trixDocument = this.editor.getDocument()
    const selectedRange = this.editor.getSelectedRange()
    const allActiveAttributes = trixDocument.getCommonAttributesAtRange(selectedRange)

    return this._extractObservedAttributesFromObject(allActiveAttributes)
  }

  _extractObservedAttributesFromObject(object) {
    let attributes = {}

    this.observedAttributes.forEach((attr) => {
      attributes[attr] = object[attr]
    })

    return attributes
  }

  _attributesEqual(a, b) {
    return objectsAreEqual(a, b)
  }

}