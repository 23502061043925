// Entry point for the build script in your package.json
import * as bootstrap from "bootstrap"

import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "trix"
import "@rails/actiontext"

import "chartkick/chart.js"

ActiveStorage.start()

import "./config"
import "./src/application"

import "./vendor/font_awesome"
import "./vendor/iconizer"
import "./vendor/bootstrap"

import "./controllers"

