import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = []
  static values = {
    delay: Number
  }

  get frameTarget() { return this.element.closest("turbo-frame") }

  connect() {
    this.timeout = setTimeout(this._triggerTimeout.bind(this), this.delayValue)
  }

  disconnect() {
    if (this.timeout) {
      this._clearTimeout()
    }
  }

  reload() {
    const { src } = this.frameTarget

    this.frameTarget.src = ""
    this.frameTarget.src = src
  }

  _triggerTimeout() {
    this._clearTimeout()

    this.reload()
  }

  _clearTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = undefined
    }
  }
}


