import Trix, { rangeIsCollapsed } from 'trix'
import BaseRichText from "./base_rich_text"

import { icon_tag } from '../../utils'
import { registerRichText, TrixAttributeObserver } from "./utils"

const DIALOG_NAME = "x-color"
const REMOVE_ALL_COLORING_BEHAVIOR = "x-remove-all-coloring"

const COLOR_ATTRIBUTES = ["foregroundColor", "backgroundColor"]

const FOREGROUND_COLOR_INPUT_NAME = "x-foreground-color"
const BACKGROUND_COLOR_INPUT_NAME = "x-background-color"

export default class ColorizableRichText extends BaseRichText {
  get buttonElement() { return this.toolbarElement.querySelector(`button[data-trix-action=${DIALOG_NAME}]`) }
  get dialogElement() { return this.toolbarElement.querySelector(`[data-trix-dialog=${DIALOG_NAME}]`) }

  get foregroundColorInput() { return this.dialogElement.querySelector(`input[name=${FOREGROUND_COLOR_INPUT_NAME}]`) }
  get backgroundColorInput() { return this.dialogElement.querySelector(`input[name=${BACKGROUND_COLOR_INPUT_NAME}]`) }
  get removeColoringButton() { return this.dialogElement.querySelector(`button[data-behavior=${REMOVE_ALL_COLORING_BEHAVIOR}]`) }

  call() {
    super.call()
    this._extendToolbar()
    this._addEventListeners()
    this._installAttributeObserver()
  }

  _extendToolbar() {
    this._attachColoringButton()
    this._attachColoringDialog()
  }

  _attachColoringButton() {
    const template = this._coloringButtonTemplate()

    this.textToolsElement.insertAdjacentHTML("beforeend", template)
  }

  _attachColoringDialog() {
    const template = this._coloringDialogTemplate()

    this.dialogsElement.insertAdjacentHTML("beforeend", template)
  }

  _installAttributeObserver() {
    this.attributeObserver = new TrixAttributeObserver(this.element, this._didChangeAttributes.bind(this))
    this.attributeObserver.observe(COLOR_ATTRIBUTES)
  }

  // =============
  // = Templates =
  // =============

  _coloringButtonTemplate() {
    const { lang } = Trix.config

    return `<button type="button" class="btn" title="${lang.pickColor}" tabindex="-1" data-trix-action='${DIALOG_NAME}'>${icon_tag("eye-dropper")}</button>`
  }

  _coloringDialogTemplate() {
    const { lang } = Trix.config

    return `
      <div class="trix-dialog trix-dialog--color" data-trix-dialog="${DIALOG_NAME}">
        <div class="row align-items-end">
          <div class='col'>
            <label class='form-label fs-6' for='trix-${FOREGROUND_COLOR_INPUT_NAME}-input'>${lang.foregroundColor}</label>
            <input type="color" id="trix-${FOREGROUND_COLOR_INPUT_NAME}-input" name="${FOREGROUND_COLOR_INPUT_NAME}" class="form-control color-input">
          </div>
          <div class='col'>
            <label class='form-label fs-6' for="trix-${BACKGROUND_COLOR_INPUT_NAME}-input">${lang.backgroundColor}</label>
            <input type="color" id="trix-${BACKGROUND_COLOR_INPUT_NAME}-input" name="${BACKGROUND_COLOR_INPUT_NAME}" class="form-control color-input">
          </div>
        </div>
        <div class='d-grid'>
          <button type='button' class='btn btn-outline-primary mt-3' data-behavior='${REMOVE_ALL_COLORING_BEHAVIOR}'>${lang.removeAllColoring}</button>
        </div>
      </div>
    `
  }

  // ==================
  // = Event Handling =
  // ==================

  _addEventListeners() {
    this.element.addEventListener("trix-toolbar-dialog-show", this._didShowDialog.bind(this))
    this.element.addEventListener("trix-toolbar-dialog-hide", this._didHideDialog.bind(this))

    this.foregroundColorInput.addEventListener("change", this._didChangeForegroundColor.bind(this))

    this.backgroundColorInput.addEventListener("change", this._didChangeBackgroundColor.bind(this))

    this.removeColoringButton.addEventListener("click", this._didClickRemoveAllColoringButton.bind(this))
  }

  _didChangeAttributes(attributes) {
    this.currentAttributes = attributes

    this._updateButton()

    if (this._dialogIsActive()) {
      this._updateDialog()
    }
  }

  _didShowDialog(e) {
    const { dialogName } = e

    if (dialogName == DIALOG_NAME) {
      this._updateDialog()
      this._updateButton()
    }
  }

  _didHideDialog(e) {
    const { dialogName } = e

    if (dialogName == DIALOG_NAME) {
      this._resetDialog()
      this._updateButton()
    }
  }

  _didChangeForegroundColor() {
    const { value } = this.foregroundColorInput

    this._editAndClose("Change foreground color", () => this._updateCurrentAttribute("foregroundColor", value))
  }

  _didChangeBackgroundColor() {
    const { value } = this.backgroundColorInput

    this._editAndClose("Change background color", () => this._updateCurrentAttribute("backgroundColor", value))
  }

  _didClickRemoveAllColoringButton() {
    this._editAndClose("Remove coloring", () => this._removeAllColoring())
  }

  // ==================
  // = Editor Updates =
  // ==================

  _editAndClose(actionDescription, callback) {
    this._recordUndoEntry(actionDescription)
    callback.call(this)
    this._hideDialog()
  }


  _updateCurrentAttribute(named, value) {
    if (this._isValidColor(value)) {
      if (this.editor.attributeIsActive(named)) {
        this.editor.deactivateAttribute(named)
      }
      this.editor.activateAttribute(named, value)
    } else {
      this.editor.deactivateAttribute(named)
    }
  }

  _removeAllColoring() {
    this._updateCurrentAttribute("foregroundColor", "")
    this._updateCurrentAttribute("backgroundColor", "")
  }

  _isValidColor(color) {
    return color
  }

  _recordUndoEntry(description) {
    const selectedRange = this.editor.getSelectedRange()

    if (!rangeIsCollapsed(selectedRange)) {
      this.editor.recordUndoEntry(description, {
        context: selectedRange,
        consolidatable: true
      })
    }
  }

  // ===================
  // = Button Handling =
  // ===================

  _updateButton() {
    const coloringActive = this._shouldActivateButton()

    this.buttonElement.classList.toggle("trix-active", coloringActive)
  }

  _shouldActivateButton() {
    return this._hasActiveColoring() || this._dialogIsActive()
  }

  // ===================
  // = Dialog Handling =
  // ===================

  _updateDialogLater() {
    requestAnimationFrame(() => this._updateDialog())
  }

  _updateDialog() {
    const attributes = this.currentAttributes

    COLOR_ATTRIBUTES.forEach((attr) => {
      this[`${attr}Input`].value = attributes[attr] || ""
    })

    this._toggleRemoveAllColoringButton(this._selectionHasAnyColoring())
  }

  _resetDialog() {
    COLOR_ATTRIBUTES.forEach((attr) => {
      this[`${attr}Input`].value = ""
    })
  }

  _hideDialog() {
    requestAnimationFrame(() => {
      // this.element.editorController.toolbarController.hideDialog()
    })

  }

  _dialogIsActive() {
    return this.dialogElement.hasAttribute("data-trix-active")
  }

  _toggleRemoveAllColoringButton(visible) {
    this.removeColoringButton.classList.toggle("d-none", !visible)
  }

  // ==================
  // = Editor Helpers =
  // ==================

  _hasActiveColoring() {
    return COLOR_ATTRIBUTES.some((attribute) => this.currentAttributes[attribute])
  }

  _selectionHasAnyColoring() {
    const trixDocument = this.editor.getDocument()
    const range = this.editor.getSelectedRange()
    const [start, end] = range

    if (rangeIsCollapsed(range)) {
      return false
    } else {
      for (let i = start; i <= end; i++) {
        const attributesAtPosition = trixDocument.getCommonAttributesAtPosition(i)


        if (attributesAtPosition.foregroundColor || attributesAtPosition.backgroundColor) {
          return true
        }
      }
    }

    return false
  }
}

registerRichText("colorizable", ColorizableRichText)