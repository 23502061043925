import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["frame"]

  get turboFrameTarget() { return (this.hasFrameTarget ? this.frameTarget : this.element) }

  connect() {
    this.sourceUrl = this.element.src
  }

  reload() {
    const { src } = this.turboFrameTarget

    this.turboFrameTarget.src = null
    this.turboFrameTarget.src = src
  }
}
