import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

const MAXIMUM_CONSECUTIVE_ERRORS = 5

export default class extends Controller {
  static targets = []

  static values = {
    interval: Number,
    url: String
  }

  connect() {
    this.startPolling()
  }

  disconnect() {
    this.stopPolling()
  }

  startPolling() {
    this.resetErrors()
    this.interval = setInterval(this.sendRequest.bind(this), this.intervalValue * 1000)
  }

  stopPolling() {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null
    }
  }

  sendRequest() {
    console.log("Sending req", this.urlValue)
    fetch(this.urlValue)
      .then((res) => res.json())
      .then(this.processRequest.bind(this))
      .then(this.resetErrors.bind(this))
      .catch(this.handleError.bind(this))
  }


  processRequest(json) {
    if (json.turbo_stream) {
      this.element.innerHTML += json.turbo_stream
    }

    if (!json.continue){
      this.stopPolling()
    }
  }

  handleError(e) {
    console.log("Polling failed with error", e)
    this.errorsCount++

    if (this.errorsCount >= MAXIMUM_CONSECUTIVE_ERRORS) {
      this.stopPolling()
      console.log("Stopped polling due to repeated errors.", this.errorsCount)
    }
  }

  resetErrors() {
    this.errorsCount = 0
  }

}
