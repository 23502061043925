import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'

export default class extends Controller {
  get modal() { return Modal.getInstance(this.element) }

  hide() {
    this.modal.hide()
  }

  show() {
    this.modal.show()
  }

  toggle() {
    this.modal.toggle()
  }

  hideLater() {
    this.hide()
  }

  hideOnSuccessfulSubmit(e) {
    if (e.detail.success) {
      this.hide()
    }
  }
}
