import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  static targets = []

  static values = {
    interval: Number
  }

  connect() {
    setTimeout(this.reload.bind(this), this.intervalValue * 1000)
  }

  reload() {
    Turbo.visit(window.location)
  }

}
